:root {
    --color-text: white; 
    --color-background: #111111; 
}

html {
    font-family: 'Epilogue', sans-serif;
}

body {
    background-color:  var(--color-background);
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    padding: 3rem;
}

.logo {
    max-width: 250px;
    margin-bottom: 3rem;
}

canvas { 
	display: block; 
	position: fixed;
	z-index: -1; 
	left: 0;
    top: 0;
    opacity: 0.33;
}

.content {
    max-width: 655px;
    margin-top: 6rem;
    font-weight: 600;
    font-size: min(1.6rem, max(2vw, 1rem));
}

.contact {
    margin-top: 2rem;
}

.contact a {
    color: var(--color-text);
}

@media screen and (min-width: 568px) {
    body {
        justify-content: space-between;
    }

    .content {
        margin-top: 0;
    }
}